import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

function SideBar() {
  const location = useLocation();
  const { pathname } = location;

  const [isMasterOpen, setIsMasterOpen] = useState(false);

  const masterPaths = ['/skills', '/shifts', '/manager', '/location', '/projectType', '/resource'];

  useEffect(() => {
    if (masterPaths.some((path) => pathname.includes(path))) {
      setIsMasterOpen(true);
    }
  }, [pathname]);

  const toggleMaster = () => setIsMasterOpen(!isMasterOpen);

  const handleLinkClick = () => {
    setIsMasterOpen(false);
  };

  return (
    <div className='py-2'>
      <div>
        <ul className="text-left">
          <li className="text-lg font-semibold border-b border-b-blue">
            <div
              onClick={toggleMaster}
              className={`flex justify-between items-center cursor-pointer ${isMasterOpen ? 'bg-blue text-white mb-2' : ''}`}
            >
              <span className="text-xl py-3 pl-6">Master</span>
              <span className="text-xl pr-6">{isMasterOpen ? <FaAngleUp /> : <FaAngleDown />}</span>
            </div>
            {isMasterOpen && (
              <ul>
                <li className={`p-3 text-lg font-semibold ${pathname.includes('/skills') ? 'bg-blue text-white mb-2' : ''}`}>
                  <Link to="/skills" className={`text-xl py-3 pl-6 ${pathname.includes('/skills') ? 'text-white' : ''}`}>Skills</Link>
                </li>
                <li className={`p-3 text-lg font-semibold ${pathname.includes('/shifts') ? 'bg-blue text-white' : ''}`}>
                  <Link to="/shifts" className={`text-xl py-3 pl-6 ${pathname.includes('/shifts') ? 'text-white' : ''}`}>Shifts</Link>
                </li>
                <li className={`p-3 text-lg font-semibold  ${pathname.includes('/manager') ? 'bg-blue text-white' : ''}`}>
                  <Link to="/manager" className={`text-xl py-3 pl-6 ${pathname.includes('/manager') ? 'text-white' : ''}`}>Managers</Link>
                </li>
                <li className={`p-3 text-lg font-semibold  ${pathname.includes('/location') ? 'bg-blue text-white' : ''}`}>
                  <Link to="/location" className={`text-xl py-3 pl-6 ${pathname.includes('/location') ? 'text-white' : ''}`}>Locations</Link>
                </li>
                <li className={`p-3 text-lg font-semibold  ${pathname === '/projectType' ? 'bg-blue text-white' : ''}`}>
                  <Link to="/projectType" className={`text-xl py-3 pl-6 ${pathname === '/projectType' ? 'text-white' : ''}`}>Project Types</Link>
                </li>
                <li className={`p-3 text-lg font-semibold  ${pathname.includes('/resource') ? 'bg-blue text-white' : ''}`}>
                  <Link to="/resource" className={`text-xl py-3 pl-6 ${pathname.includes('/resource') ? 'text-white' : ''}`}>Resources</Link>
                </li>
              </ul>
            )}
          </li>
          <li className={`p-3 pl-0 text-lg font-semibold border-b border-b-blue ${pathname === '/project' ? 'bg-blue text-white' : ''}`}>
            <Link to="/project" className={`text-xl py-3 pl-6 ${pathname === '/project' ? 'text-white' : ''}`} onClick={handleLinkClick}>Project</Link>
          </li>
          <li className={`p-3 text-lg font-semibold border-b border-b-blue ${pathname === '/availableResources' ? 'bg-blue text-white' : ''}`}>
            <Link to="/availableResources" className={`text-xl py-3 ${pathname === '/availableResources' ? 'text-white' : ''}`} onClick={handleLinkClick}>Resource Availability</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
