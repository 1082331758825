
// get first letter capital
export const getEveryFirstWordCaps = (value) => {
    if (!value) return "";
    return value
        .split(" ")
        .map(word => word[0].toUpperCase() + word.slice(1))
        .join(" ");
};

// time format 24 hour
export const timeTo24HourFormat = (time) => {
    const [hour, period] = time.split(" ");
    let hour24 = parseInt(hour, 10);
    if (period === "PM" && hour24 !== 12) hour24 += 12;
    if (period === "AM" && hour24 === 12) hour24 = 0;
    return hour24;
};

// time format 12 hour
export const timeTo12HourFormat = (hour) => {
    const period = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12} ${period}`;
};